import ConsultaDNI from "./components/ConsultaDNI/ConsultaDNI";

function App() {
  return (
    <div className="App">
      <ConsultaDNI />
    </div>
  );
}

export default App;
