// import React, { Component } from 'react';
// import Swal from 'sweetalert2';
// import 'sweetalert2/dist/sweetalert2.min.css';
// import './ConsultaDNI.css';

// class ConsultaDNI extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       dni: '',
//       estado: '',
//       message: '',
//       isReadyForInstall: false,
//     };
//   }

//   componentDidMount() {
//     window.addEventListener("beforeinstallprompt", (event) => {
//       // Prevent the mini-infobar from appearing on mobile.
//       event.preventDefault();
//       console.log("👍", "beforeinstallprompt", event);
//       // Stash the event so it can be triggered later.
//       window.deferredPrompt = event;
//       // Remove the 'hidden' class from the install button container.
//       this.setState({ isReadyForInstall: true });
//     });
//   }

//   handleChange = (e) => {
//     this.setState({ dni: e.target.value });
//   };

//   handleSubmit = (e) => {
//     e.preventDefault();

//     // Establece la URL de la API PHP
//     // const apiUrl = "http://localhost:80/backend/api.php"; // Ajusta el puerto si es diferente
//     //const apiUrl = "https://am.asijemin.org.ar/backend/api.php"; // Ajusta el puerto si es diferente
//       const apiUrl = "https://am.asijemin.org.ar/test/consulta.php"; // Ajusta el puerto si es diferente


      
//       const dniRegex = /^\d{8}$/;
//       if (!dniRegex.test(this.state.dni)) {

//         // Mostrar SweetAlert con mensaje de error
//         Swal.fire({
//           title: "Error!",
//           text: "El DNI ingresado no tiene el formato correcto (debe ser un número de 8 dígitos).",
//           icon: "error"
//         });
//         return; // Detener la ejecución si el DNI no tiene el formato correcto
//       }
    


//     // Realiza la solicitud a la API PHP
//     fetch(apiUrl, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded',
//       },
//       body: `dni=${this.state.dni}`,
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (this.state.dni === '') {
//           // DNI está vacío
//           Swal.fire({
//             title: 'Error',
//             text: 'No hay ningún carácter',
//             icon: 'error',
//             confirmButtonText: 'Entendido',
//           });
//         } else if (!/^[0-9]+$/.test(this.state.dni)) {
//           // DNI contiene letras o caracteres no numéricos
//           Swal.fire({
//             title: 'Atencion',
//             text: 'Solo son válidos números, no se permiten letras ni puntos',
//             icon: 'warning',
//             confirmButtonText: 'Entendido',
//           });
//         } else if (data.message === "No se encontró ningún afiliado con el DNI proporcionado.") {
//           // No se encontró ningún afiliado con el DNI proporcionado
//           Swal.fire({
//             title: 'Error',
//             text: 'No se encontró ningún afiliado con el DNI proporcionado',
//             icon: 'error',
//             confirmButtonText: 'Entendido',
//           });
//         } else {
//           // Respuesta exitosa
//           let title, text, icon, confirmButtonText;

//           if (data.estado === 'Alta') {
//             title = 'Afiliado de Alta';
//             text = 'El afiliado puede recibir beneficios.';
//             icon = 'success';
//             confirmButtonText = 'Aceptar';
//           } else if (data.estado === 'Expulsado') {
//             title = 'Afiliado Expulsado';
//             text = 'El afiliado no puede recibir ningún beneficio.';
//             icon = 'error';
//             confirmButtonText = 'Cerrar';
//           } else {
//             title = 'Afiliado de Baja';
//             text = 'El afiliado no puede recibir beneficios.';
//             icon = 'error';
//             confirmButtonText = 'Cerrar';
//           }

//           Swal.fire({
//             title,
//             text,
//             icon,
//             confirmButtonText,
//           });
//         }

//         // Borra el contenido del campo DNI después de la consulta exitosa o de un error
//         this.setState({ dni: '' });
//       })
//       .catch((error) => {
//         console.error('Error:', error);
//       });
//   };

//   downloadApp = async () => {
//     console.log("👍", "butInstall-clicked");
//     const promptEvent = window.deferredPrompt;
//     if (!promptEvent) {
//       // The deferred prompt isn't available.
//       console.log("oops, no prompt event guardado en window");
//       return;
//     }
//     // Show the install prompt.
//     promptEvent.prompt();
//     // Log the result
//     const result = await promptEvent.userChoice;
//     console.log("👍", "userChoice", result);
//     // Reset the deferred prompt variable, since
//     // prompt() can only be called once.
//     window.deferredPrompt = null;
//     // Hide the install button.
//     this.setState({ isReadyForInstall: false });
//   };

//   render() {
//     return (
//       <div className='min-h-screen flex items-center '>
//         <div className='w-1/2 h-screen overflow-hidden '>
//            <img src="https://i.pinimg.com/originals/9c/fd/56/9cfd56bf88a7ed515d5d239454c70703.png" alt="" className=" w-100 w-full h-auto" /> 
//         </div>

//         <div className='w-1/2'>

//         <div className='  w-3/4 mx-auto'>
//             <img src="/asijeminlogo.png" alt="logo asijemin" className='mx-auto' />
//         </div>
        


//           <div className='contenedor w-3/4 mx-auto'>
//             <div className='mt-20 interior text-center'>
//               <h1 className='text-3xl font-[Roboto] text-black'>Consulta de Estado de Afiliado</h1>

//               <form onSubmit={this.handleSubmit} className='mt-8 ml-8 text-center'>
//                 <label className=' font-[Roboto] text-xl mb-4'>
//                   ingrese el DNI a consultar
//                   <input
//                     className='border-2 border-black  w-full rounded-lg mt-5 mb-2 px-2 py-2'
//                     type="text"
//                     value={this.state.dni}
//                     onChange={this.handleChange}
//                   />
//                 </label>

//                 <div className="mt-2">
//                   <button
//                     type="submit"
//                     className='bg-green-600 w-full py-2 px-3 text-white hover:bg-green-700 rounded-lg'
//                   >
//                     Consultar
//                   </button>
//                 </div>
//               </form>

//               {this.state.isReadyForInstall && (
//                 <button onClick={this.downloadApp} className='bg-red-600  py-2  text-white hover:bg-red-700 rounded-lg mt-3 px-5'>Descargar la Aplicacion</button>
//               )}

//               <div>
//                 <p>{this.state.message}</p>
//                 {this.state.estado && (
//                   <p>Estado del afiliado: {this.state.estado}</p>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default ConsultaDNI;



import React, { Component } from 'react';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import './ConsultaDNI.css';

class ConsultaDNI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dni: '',
      estado: '',
      message: '',
      isReadyForInstall: false,
    };
  }

  componentDidMount() {
    window.addEventListener("beforeinstallprompt", (event) => {
      // Prevent the mini-infobar from appearing on mobile.
      event.preventDefault();
      console.log("👍", "beforeinstallprompt", event);
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event;
      // Remove the 'hidden' class from the install button container.
      this.setState({ isReadyForInstall: true });
    });
  }

  handleChange = (e) => {
    this.setState({ dni: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    // Establece la URL de la API PHP
    // const apiUrl = "http://localhost:80/backend/api.php"; // Ajusta el puerto si es diferente
    //const apiUrl = "https://am.asijemin.org.ar/backend/api.php"; // Ajusta el puerto si es diferente
      const apiUrl = "https://am.asijemin.org.ar/test/consulta.php"; // Ajusta el puerto si es diferente


      
      const dniRegex = /^\d{8}$/;
      if (!dniRegex.test(this.state.dni)) {

        // Mostrar SweetAlert con mensaje de error
        Swal.fire({
          title: "Error!",
          text: "El DNI ingresado no tiene el formato correcto (debe ser un número de 8 dígitos).",
          icon: "error"
        });
        return; // Detener la ejecución si el DNI no tiene el formato correcto
      }
    


    // Realiza la solicitud a la API PHP
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `dni=${this.state.dni}`,
    })
      .then((response) => response.json())
      .then((data) => {
        if (this.state.dni === '') {
          // DNI está vacío
          Swal.fire({
            title: 'Error',
            text: 'No hay ningún carácter',
            icon: 'error',
            confirmButtonText: 'Entendido',
          });
        } else if (!/^[0-9]+$/.test(this.state.dni)) {
          // DNI contiene letras o caracteres no numéricos
          Swal.fire({
            title: 'Atencion',
            text: 'Solo son válidos números, no se permiten letras ni puntos',
            icon: 'warning',
            confirmButtonText: 'Entendido',
          });
        } else if (data.message === "No se encontró ningún afiliado con el DNI proporcionado.") {
          // No se encontró ningún afiliado con el DNI proporcionado
          Swal.fire({
            title: 'Error',
            text: 'No se encontró ningún afiliado con el DNI proporcionado',
            icon: 'error',
            confirmButtonText: 'Entendido',
          });
        } else {
          // Respuesta exitosa
          let title, text, icon, confirmButtonText;

          if (data.estado === 'Alta') {
            title = 'Afiliado de Alta';
            text = 'El afiliado puede recibir beneficios.';
            icon = 'success';
            confirmButtonText = 'Aceptar';
          } else if (data.estado === 'Expulsado') {
            title = 'Afiliado Expulsado';
            text = 'El afiliado no puede recibir ningún beneficio.';
            icon = 'error';
            confirmButtonText = 'Cerrar';
          } else {
            title = 'Afiliado de Baja';
            text = 'El afiliado no puede recibir beneficios.';
            icon = 'error';
            confirmButtonText = 'Cerrar';
          }

          Swal.fire({
            title,
            text,
            icon,
            confirmButtonText,
          });
        }

        // Borra el contenido del campo DNI después de la consulta exitosa o de un error
        this.setState({ dni: '' });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  downloadApp = async () => {
    console.log("👍", "butInstall-clicked");
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      // The deferred prompt isn't available.
      console.log("oops, no prompt event guardado en window");
      return;
    }
    // Show the install prompt.
    promptEvent.prompt();
    // Log the result
    const result = await promptEvent.userChoice;
    console.log("👍", "userChoice", result);
    // Reset the deferred prompt variable, since
    // prompt() can only be called once.
    window.deferredPrompt = null;
    // Hide the install button.
    this.setState({ isReadyForInstall: false });
  };


  render() {
    return (
      <div className={`min-h-screen flex items-center ${window.innerWidth > 767 ? 'imagen-desktop' : 'imagen-mobile'}`}>
        <div className='w-1/2 bg-slate-600'>
          <img src="https://i.pinimg.com/originals/9c/fd/56/9cfd56bf88a7ed515d5d239454c70703.png" alt="" className="ml-0" />
        </div>

        <div className='w-1/2'>
          <div className="contenedor">
            <div className="interior text-center">
            <div className="logo">
            </div>

              {/* <img src="/asijeminlogo.png" alt="logo asijemin" className='mx-auto mb-8 logo' /> */}
              <h1 className="text-3xl font-[Roboto] text-black">
                Consulta de Estado de Afiliado
              </h1>

              <form onSubmit={this.handleSubmit} className="mt-8 ml-8 text-center">
                <label className="font-[Roboto] text-xl mb-4">
                  ingrese el DNI a consultar
                  <input
                    className="border-2 border-black w-full rounded-lg mt-5 mb-2 px-2 py-2"
                    type="text"
                    value={this.state.dni}
                    onChange={this.handleChange}
                  />
                </label>

                <div className="mt-2">
                  <button
                    type="submit"
                    className="bg-green-600 w-full py-2 px-3 text-white hover:bg-green-700 rounded-lg"
                  >
                    Consultar
                  </button>
                </div>
              </form>

              {this.state.isReadyForInstall && (
                <button onClick={this.downloadApp} className='   py-2  text-white  rounded-lg mt-3 px-5 btn-app'>Descargar la Aplicacion</button>
              )}

              <div>
                <p>{this.state.message}</p>
                {this.state.estado && (
                  <p>Estado del afiliado: {this.state.estado}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConsultaDNI;

